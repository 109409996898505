<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <stepperGeneric :steps="steps" @complete="handleCompletion"></stepperGeneric>
  </div>
</template>

<script>
import stepperGeneric from '../../components/stepper/devis112/stepperGeneric.vue';
import stepAdresseBar112 from '../../components/stepper/devis112/stepAdresseBar112.vue';
import stepChaniterBar112 from '../../components/stepper/devis112/stepChaniterBar112.vue';
import stepClientBar112 from '../../components/stepper/devis112/stepClientBar112.vue';
import stepGeneraliteBar112 from '../../components/stepper/devis112/stepGeneraliteBar112.vue';
import stepInformationBar112 from '../../components/stepper/devis112/stepInformationBar112.vue';
import stepInstallateurBar112 from '../../components/stepper/devis112/stepInstallateurBar112.vue';
import stepReglementBar112 from '../../components/stepper/devis112/stepReglementBar112.vue';
import stepDevisBar112 from '../../components/stepper/devis112/stepDevisBar112.vue';

import { mapGetters } from "vuex";

export default {
  components: {
    stepperGeneric,
  
  },
  data() {
    return {
      steps: [
        { label: 'Généralité', component: stepGeneraliteBar112 },
        { label: 'Client', component: stepClientBar112 },
        { label: 'Installateur', component: stepInstallateurBar112 },
        { label: 'Adresse', component: stepAdresseBar112 },
        { label: 'Information', component: stepInformationBar112 },
        { label: 'Chantier', component: stepChaniterBar112 },
        { label: 'Règlement', component: stepReglementBar112 },
        { label: 'Devis', component: stepDevisBar112 },
      ],
    };
  },
  computed: {
    ...mapGetters(["getTabs"]),
  },
  methods: {
    handleCompletion() {
      this.$router.push('/devis/list');
    },
  },
};
</script>

  
  <style scoped>
  .inner-container .content {
    margin: 10px 15px;
    background-color: #fff;
    box-shadow: 1px 1px 24px #00000019;
    border-radius: 5px;
    padding: 14px;
    min-height: 88vh;
    margin-top: 55px;
  }
  </style>